import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import notFound from "../images/svg/undraw_to_the_moon.svg";

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Ei löydy" />

    <h1>Osoitetta ei löytynyt!</h1>
    <p>Päädyit juuri osoitteeseen, jota ei ole olemassa... kyynel. Oletettavasti osoite on laukaistu avaruuteen.</p>
    <div style={{ maxWidth: `350px`, marginBottom: `1.45rem`, marginTop: `1.45rem` }}>
        <img
            src={notFound}
            alt="404"
        />
    </div>
  </Layout>
)

export default NotFoundPage
